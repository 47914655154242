console.log('JS OK');


/////////////////////////////
//FONCTIONNEMENT SOUS MENU CAT

var btnCat = document.querySelector(".btnCat");
var sousMenu = document.querySelector(".sousMenu");

btnCat.addEventListener("click", function(){
	sousMenu.classList.toggle("ouvert");
	btnCat.classList.toggle("ouvert");
	if(menuMobile.classList.contains("ouvert")){
	//Rien
	}else{
		page.classList.toggle("noScroll");
	}

});



// /////////////////////////////
// // OBTENIR LA COULEUR DOMINANTE D'UNE IMAGE
// // http://jsfiddle.net/xLF38/818/


// //Si image d'entete
// var imgEntete = document.querySelector(".imgEntete");
// if (imgEntete) {


// 	var rgb = getAverageRGB(document.querySelector('.imgEntete img'));
// 	// document.body.style.backgroundColor = 'rgb('+rgb.r+','+rgb.g+','+rgb.b+')';

// 	function getAverageRGB(imgEl) {
	    
// 	    var blockSize = 5, // only visit every 5 pixels
// 	        defaultRGB = {r:0,g:0,b:0}, // for non-supporting envs
// 	        canvas = document.createElement('canvas'),
// 	        context = canvas.getContext && canvas.getContext('2d'),
// 	        data, width, height,
// 	        i = -4,
// 	        length,
// 	        rgb = {r:0,g:0,b:0},
// 	        count = 0;
	        
// 	    if (!context) {
// 	        return defaultRGB;

// 	    }
	    
// 	    height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
// 	    width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
	    
// 	    context.drawImage(imgEl, 0, 0);
	    
// 	    try {
// 	        data = context.getImageData(0, 0, width, height);

// 	    } catch(e) {
// 	        /* security error, img on diff domain */
// 	        return defaultRGB;
			 
// 	    }
	    
// 	    length = data.data.length;
	    
// 	    while ( (i += blockSize * 4) < length ) {
// 	        ++count;
// 	        rgb.r += data.data[i];
// 	        rgb.g += data.data[i+1];
// 	        rgb.b += data.data[i+2];
// 	    }
	    
// 	    // ~~ used to floor values
// 	    rgb.r = ~~(rgb.r/count);
// 	    rgb.g = ~~(rgb.g/count);
// 	    rgb.b = ~~(rgb.b/count);
	    

// 	    return rgb;
	    
// 	}



// 	/////////////////////////////
// 	// CHANGER LA COULEUR DU MENU SELON CONTRASTE DE L'IMAGE
// 	// http://jsfiddle.net/alex_ball/PXJ2C/
// 	// https://stackoverflow.com/questions/11867545/change-text-color-based-on-brightness-of-the-covered-background-area
// 	//https://wunnle.com/dynamic-text-color-based-on-background
// 	//http://www.w3.org/TR/AERT#color-contrast

// 	var contrasteCouleur = Math.round(((parseInt(rgb.r) * 299) + (parseInt(rgb.g) * 587) + (parseInt(rgb.b) * 114)) /1000);


// 	console.log(contrasteCouleur);

// 	if(contrasteCouleur > 125) {
// 	     document.querySelector("#header").classList.add('imageClaire')
// 	}else{ 
// 	     document.querySelector("#header").classList.add('imageFoncee')
// 	} 



// } //Fin if Entete







//Var
var homepage = document.querySelector(".home");
var archivepage = document.querySelector(".archive");



// UNIQUEMENT SUR HOME ET ARCHIVE
if ( homepage || archivepage) {

/////////////////////////////
// MASONRY
// https://masonry.desandro.com
//-------------------------------------//

	var grid = document.querySelector('#listeProjets');

	var msnry = new Masonry( grid, {
  itemSelector: 'none', // select none at first
  columnWidth: '.grid-sizer',
  gutter: '.gutter-sizer',
  percentPosition: true,
  stagger: 30,
  // nicer reveal transition
  visibleStyle: { transform: 'translateY(0)', opacity: 1 },
  hiddenStyle: { transform: 'translateY(100px)', opacity: 0 },
});

// initial items reveal
imagesLoaded( grid, function() {
  grid.classList.remove('are-images-unloaded');
  msnry.options.itemSelector = '.blocMiniature';
  var items = grid.querySelectorAll('.blocMiniature');
  msnry.appended( items );
});


//-------------------------------------//
// init Infinite Scroll

	var infScroll = new InfiniteScroll( grid, {
		path: '.nextPageLink a',
		append: '.blocMiniature',
		outlayer: msnry,
		status: '.page-load-status',
		history: false,
		hideNav: '.nextPrev',
		debug: true
	});


	//BUG : viédo ne sont pas lu en autoplay si chargée en ajax
	function lireVideoApresAjax(){
		const lecteurVideo = document.querySelectorAll("video");

		lecteurVideo.forEach(function(videoElem){
			// videoElem.classList.add('test');
			console.log(">>> video en AJAX : play !");
			videoElem.play();


		});
	}

	infScroll.on( 'append', function() {
		console.log('articles ajoutés');
		lireVideoApresAjax();
	});



} //FIN IF HOME ET ARCHIVE


/////////////////////////////
// ON SCROLL : cacher des éléments du header
// Référence: http://www.html5rocks.com/en/tutorials/speed/animations/

var derniere_position_de_scroll_connue = 0;
var ticking = false;


var menuAcacher = document.querySelectorAll(".animate");

function faitQuelquechose(position_scroll) {
  // faites quelque chose avec la position du scroll
  // console.log(position_scroll);
	if (position_scroll > 80) {
		Array.prototype.forEach.call(menuAcacher, function(el, i){
			el.classList.add("cacher");
		});

	}else{
		Array.prototype.forEach.call(menuAcacher, function(el, i){
			el.classList.remove("cacher");
		});
	}
}

window.addEventListener('scroll', function(e) {
	derniere_position_de_scroll_connue = window.scrollY;
	if (!ticking) {
		window.requestAnimationFrame(function() {
			faitQuelquechose(derniere_position_de_scroll_connue);
			ticking = false;
		});
	}
	ticking = true;
});

// // // ANIM T

// console.log('animation du logo activée');

var is_home = document.querySelector(".home");


//Chapeaux x 6
var chapeaux = new Array();
chapeaux[0] = [2,3,4,6,7,8];
chapeaux[1] = [1,2,3,4,5,6,7,8];
chapeaux[2] = [2,4,6,7];
chapeaux[3] = [3,4,7,8];
chapeaux[4] = [1,3,4,5,7,8];
chapeaux[5] = [1,2,4,5,6,7];

//Pieds x 14
var pieds = new Array();
pieds[0] = [10,12,14,15,22,24,21,23,20,17,19,18];
pieds[1] = [12,15,20,17,23,21];
pieds[2] = [12,15,20,23];
pieds[3] = [12,9,18,20,23,22,13,15];
pieds[4] = [11,10,12,16,14,15,20,23,21,17];
pieds[5] = [10,12,9,18,20,17,23,21,22,13,15,14];
pieds[6] = [10,12,14,15,23,20];
pieds[7] = [10,12,9,15,14,13,20,23,17,21];
pieds[8] = [10,12,14,15,20,17,23,21];
	//pieds droits
pieds[9] = [10,12,15,14,25,26,20,23,27,28];
pieds[10] = [12,15,25,26,20,23,27,28];
pieds[11] = [12,15,25,26,20,23,17,21];
pieds[12] = [10,14,12,15,25,26,20,23,17,21];
pieds[13] = [12,15,25,26,20,23];

//Création de toute les combinaisons de T (logo tabara = 66)
var tComplet = new Array();
var a=0;
for(var i=0; i < chapeaux.length; i++){
	for(var e=0; e < pieds.length; e++){
		tComplet[a] = chapeaux[i].concat(pieds[e]);
		a++; 
	}
}
// console.log("tComplet = "+tComplet.length+" possibilités");

//Le T à afficher par défaut
var defautvalue;
if (is_home) {
	defautvalue = 66;
}else{
	defautvalue =Math.floor((Math.random()*tComplet.length)+1);
}
//console.log(is_home);

// console.log(defautvalue);

var tDefaut = tComplet[defautvalue]; 
// console.log('tDefaut = '+tDefaut);

//Rangement aléatoire du tableaux (optionnel)
tComplet.sort(function() { return 0.5 - Math.random() });
// console.log("tComplet = "+tComplet);


//Apparition du T
var triangle, tActuel, tSelec;

var tApparition = function(tChoisi){
	if (typeof tActuel == 'undefined') {
		tActuel = tChoisi;
		// console.log('1 - tActuel = '+tActuel);
	}else{
		tActuel = tSelec;
		// console.log('2 - tActuel = '+tActuel);
	}
	tSelec = tChoisi;
	//Rangement aléatoire du tableaux (optionnel)
	tSelec.sort(function() { return 0.5 - Math.random() });

	// console.log('3 - tSelec = '+tSelec);
	var delai;
	for (var i=0;i<tSelec.length;i++)
	{ 
		// console.log('tApparition, i='+i);
		// console.log('p' + tSelec[i]);
	    triangle = document.getElementById('p' + tSelec[i]);
	    // triangle.delay(i*50).fadeIn();
		triangle.style.transition = i*.1 + "s";    
		triangle.classList.add("show");
		triangle.classList.remove("hide");
	}
};

//Effacement du T
var tEfface = function(){
	for (var i=0;i<tActuel.length;i++)
	{ 
		// console.log('tEfface, i='+i);
		// console.log('p' + tActuel[i]);
	    triangle = document.getElementById('p' + tActuel[i]);
	    // triangle.delay(i*25).fadeOut();
		triangle.style.transition = i*.09 + "s";    
		triangle.classList.add("hide");
		triangle.classList.remove("show");
	}		
};

//Declenchement (par défaut = logo tabaramounien = 66)
tApparition(tDefaut);


//Declenchement au survol
var incr = 0;
var logoAnim = document.querySelector(".logoAnim")
logoAnim.addEventListener("mouseenter", function( ) {
	// console.log("enter valeur incr = "+incr);
	tEfface();
	tApparition(tComplet[incr]);
	incr++;
});
logoAnim.addEventListener("mouseleave", function( ) {
	// console.log("leave valeur incr = "+incr);
	tEfface();
	tApparition(tComplet[incr]);		
	incr++;
});
